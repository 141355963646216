<template>
  <div class="box p-2 items-center">
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <i class="pi pi-angle-left text-blue-900 my-auto cursor-pointer"
           style="font-size: 1.5rem"
           @click="$router.go(-1)"
        />
        <span class="text-lg font-bold">Procesos Judiciales</span>
      </div>
      <Button v-if="$can('pharmasan.administrativa.juridica.procesos-judiciales.access')" label="Nuevo" icon="pi pi-plus" class="bg-blue-900" @click="goto('pharmasan.administrativa.juridica.procesos-judiciales.crear')"/>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'ProcesosJudicialesMainListadoHeader',
  setup () {
    const router = useRouter()
    const goto = (name) => {
      router.push({ name })
    }
    return {
      goto
    }
  }
}
</script>

<style scoped>

</style>
